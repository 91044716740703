import React from "react"

import Layout from "../components/layout";
import ThankYouFreeEstimate from '../components/thankYou/freeEstimate.tsx';
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="C & R Asphalt | Thank you" />
    <ThankYouFreeEstimate />
  </Layout>
)

export default NotFoundPage
